@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
}

.root.uncheckable {
  pointer-events: none;
  filter: grayscale(1);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  padding: 0;

  background-color: rgb($colorLightBlue);
  transition: background-color $transition;

  &:hover {
    background-color: rgb($colorSmoothBlueHover);
  }

  &:active {
    background-color: rgb($colorSmoothBlueActive);
  }
}

.btn.btnDisabled svg path {
  fill: rgb($colorGray80);
}

.btnDec {
  border-radius: 4px 0 0 4px;
}

.btnInc {
  border-radius: 0 4px 4px 0;
}

.counterValue {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  min-width: 24px;
  padding: 0 8px;

  background-color: rgb($colorLightBlue);
}
