@import 'pages/variables.css';

.root {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  padding-right: 4px;
  width: 100%;
  cursor: text;
  color: rgb($colorGray60);
}

.placeName {
  color: rgb($colorBlack);
}
