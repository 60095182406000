@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
  width: 100%;
}

.wrapper {
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  flex-shrink: 1;

  position: relative;

  padding: 4px 4px 4px 8px;

  border: 1px solid rgb($colorGray);
  border-radius: 4px;
  background: rgb($colorWhite);
}

.central {
  border: none;
}

.central.active {
  border: 1px solid rgb($colorGray);
}

.icon {
  width: 24px;
  flex-shrink: 0;
}

.icon.central path {
  fill: rgb($colorBlue);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  padding: 8px;
}

@media all and (min-width: $breakpointSmall) {
  .wrapper {
    padding: 4px 4px 4px 8px;
  }

  .central,
  .central.active {
    border: none;
  }
}

@media all and (min-width: $breakpointMedium) {
  .wrapper {
    flex-shrink: 0;
  }
}
