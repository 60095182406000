@import 'pages/variables.css';

.root {
  &:hover svg path {
    fill: rgb($colorHover);
  }

  &:hover .text {
    color: rgb($colorHover);
  }
}

.root:not(.isFlatOwner) {
  width: 48px;
}

.isFlatOwner:not(:last-child) {
  margin-right: 16px;
}

.root svg {
  width: 100%;
  height: 100%;
}

.root svg path,
.text {
  transition: fill $transition;
}

.root.mark .icon {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    right: 1px;
    width: 10px;
    height: 10px;
    background-color: rgb($colorOrange);
    border: 2px solid rgb($colorWhite);
    border-radius: 50%;
  }
}

.root.mark.central .icon {

&::after {
   top: 2px;
   right: 2px;
   border: none;
   width: 5px;
   height: 5px;
 }
}

.root.active svg path {
  fill: rgb($colorBlack);
}

.root.active .text {
  color: rgb($colorBlack);
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.isFlatOwner .text {
  display: none;
}

@media all and (min-width: 360px) {
  .root {
    width: 48px;

    &:not(:last-child) {
      margin-right: 0;
    }
  }

  .root .text {
    display: inline-block;
  }
}

/* desktop */
@media all and (min-width: $breakpointSmall) {
  .root:not(:last-child) {
    margin-right: 16px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .root.mark.central .icon {
    &::after {
       width: 7px;
       height: 7px;
     }
  }
}
