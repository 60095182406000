@import "pages/variables.css";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  padding: 0;
}
