@import 'pages/variables.css';

.root {
  display: flex;
  flex-direction: column;
  margin: 0;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
}

.root:hover,
.root.focus {
  background-color: rgb($colorSmoothBlueBitDarker);
}

.root:active,
.root.focus:active {
  background-color: rgb($colorSmoothBlueHover);
}

.root:first-of-type {
  border-top: none;
}

.description {
  color: rgb($colorSmoothDarkGray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (min-width: $breakpointSmall) {
  .root {
    padding: 8px 16px;
  }

  .root:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  .root:last-of-type {
    border-radius: 0 0 4px 4px;
  }
}
