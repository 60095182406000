@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  color: rgb($colorBlue);

  font-size: 16px;
  line-height: 20px;
  height: 32px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  height: 40px;

  background-color: rgb($colorSmoothBlue);
  user-select: none;
  transition: background-color ease 0.25s;
}

.button:hover {
  cursor: pointer;
  background-color: rgb($colorSmoothBlueBitDarker);
}

.button:active {
  background-color: rgb($colorSmoothBlueActive);
}

.button.active {
  background-color: rgb($colorSmoothBlueHover);
}

.button.list {
  display: none;
}

.button.map {
  width: 106px;
  border-radius: 4px;
}

.icon {
  margin-right: 8px;
}

@media (min-width: $breakpointSmall) {
  .root {
    height: 40px;
  }
}

@media (min-width: $breakpointMedium) {
  .button.list {
    display: flex;
    width: 116px;
    border-radius: 4px 0 0 4px;
  }

  .button.map {
    border-radius: 0 4px 4px 0;
  }
}
