@import 'pages/variables.css';

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  border-top: 1px solid rgb($colorGray);
  color: rgb($colorBlue);
}

.textWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  margin-right: 8px;
}
