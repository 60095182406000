@import "pages/variables.css";

.root {
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.firstWeek {
}

.pastWeek {
  pointer-events: none;
  color: rgb($colorGray);
}

.weekSize-1 {
  grid-template-columns: repeat(1fr);
  width: calc(1 / 7 * 100%);
}

.weekSize-2 {
  grid-template-columns: repeat(2, 1fr);
  width: calc(2 / 7 * 100%);
}

.weekSize-3 {
  grid-template-columns: repeat(3, 1fr);
  width: calc(3 / 7 * 100%);
}

.weekSize-4 {
  grid-template-columns: repeat(4, 1fr);
  width: calc(4 / 7 * 100%);
}

.weekSize-5 {
  grid-template-columns: repeat(5, 1fr);
  width: calc(5 / 7 * 100%);
}

.weekSize-6 {
  grid-template-columns: repeat(6, 1fr);
  width: calc(6 / 7 * 100%);
}

.weekSize-7 {
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}

@media (max-height: 575px), (max-width: 575px) {
  .pastWeek {
    display: none;
  }
}

@media (min-height: 576px) and (min-width: 576px) {
  .pastWeek.firstWeek {
    justify-content: flex-end;
  }
}
