@import "pages/variables.css";

.root {
}

.title {
  margin-left: calc(100% / 14 - 1ch);
  font-weight: bold;
}

.ruler {
  display: none;
}

.weeks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.yearTitle {

}

.optionalYear {

}

@media (max-height: 575px), (max-width: 575px) {
  .root {
    margin: 0 8px 4px;
  }

  .title {
    padding: 8px 0;
    margin-bottom: 0;
  }

  .desktopTitle {
    display: none;
  }

  .mobileTitle {
    display: inline-block;
    flex-grow: 1;
  }

  .optionalYear {
    display: none;
  }
}

@media (min-height: 576px) and (min-width: 576px) {
  .root {
    min-width: 280px;
    margin: 0 32px 8px 0;

    &:first-child {
      margin-left: -12px;
    }
  }

  .title {
    margin-bottom: 16px;
  }

  .desktopTitle {
    display: inline-block;
  }

  .mobileTitle {
    display: none;
  }

  .ruler {
    display: block;
  }
}
