@import "pages/variables.css";

.root {
  display: inline-flex;
  justify-content: center;
  padding: 8px 0;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
  }
}

.weekend {
  /**/
}

.arrivalDay .waveLeft,
.lastHoveredDay .waveRight,
.departureDay .waveRight {
  display: none;
}

.arrivalDay.lastHoveredDay .waveRight {
  display: unset;
}

.disabledDay,
.disabledDay.weekend {
  color: rgb($colorGray);
}

.disabledDay.selectedDay,
.disabledDay.hoveredDay {
  color: rgb($colorBlack);
}

.hoveredDay,
.selectedDay {
  background-color: rgb($colorSmoothBlueHover);

  &:hover {
    border-radius: 0;
  }
}

.arrivalDay,
.arrivalDay:hover {
  border-radius: 4px 0 0 4px;
}

.arrivalDay.lastHoveredDay,
.arrivalDay.lastHoveredDay:hover {
  border-radius: 4px 0 0 4px;
}

.departureDay,
.departureDay:hover,
.lastHoveredDay,
.lastHoveredDay:hover {
  border-radius: 0 4px 4px 0;
}

.isStartOfBooking,
.isStartOfBooking:hover {
   color: rgb($colorSmoothDarkGray);
 }


.raggedRightBorder.selectedDay .waveRight,
.raggedLeftBorder.selectedDay .waveLeft {
  fill: rgb($colorSmoothBlueHover);
  background-color: rgba($colorWhite, 0);
}

.raggedRightBorder.selectedDay:active .waveRight,
.raggedLeftBorder.selectedDay .waveLeft {
  fill: rgb($colorSmoothBlueHover);
  background-color: rgba($colorWhite, 0);
}

.raggedRightBorder.selectedDay.availableDay:active .waveRight,
.raggedLeftBorder.selectedDay.availableDay:active .waveLeft {
  fill: rgb($colorBlue);
  background-color: rgba($colorWhite, 0);
}

.wave {
  position: absolute;
  fill: rgba($colorSmoothBlueHover, 0);
  height: 100%;
  top: 0;
}

.waveLeft {
  left: 0;
}

.waveRight {
  right: 0;
}

.disabledDay.departureDay.arrivalDay {
  border-radius: 4px;
}

.error.hoveredDay,
.error.selectedDay {
    background-color: rgb($colorSmoothRedHover);
  }

.error.hoveredDay.disabledDay,
.error.selectedDay.disabledDay {
    color: rgb($colorBlack);
  }

.error.disabledDay.selectedDay {
  color: rgb($colorBlack);
  }

.error.selectedDay .waveRight,
.error.hoveredDay .waveRight,
.error.selectedDay .waveLeft,
.error.hoveredDay .waveLeft {
    fill: rgb($colorSmoothRedHover);
    background-color: rgba($colorWhite, 0);
  }

.error.selectedDay:active .waveRight,
.error.hoveredDay:active .waveRight,
.error.selectedDay:active .waveLeft,
.error.hoveredDay:active .waveLeft {
    fill: rgb($colorSmoothRedHover);
    background-color: rgba($colorWhite, 0);
  }

.error.selectedDay.availableDay:active .waveRight,
.errorselectedDay.availableDay:active .waveLeft {
    fill: rgb($colorBlue);
    background-color: rgba($colorWhite, 0);
  }

@media (max-height: 575px), (max-width: 575px) {
  .selectedDay.availableDay.lastDay .wave,
  .selectedDay.availableDay.firstDay .wave {
    background-color: rgb($colorSmoothBlueHover);
  }

  .selectedDay.availableDay.lastDay.raggedRightBorder .wave,
  .selectedDay.availableDay.firstDay.raggedLeftBorder .wave {
    background-color: transparent;
  }

  .wave {
    height: 100%;
    top: 0;
    width: 13px;
  }

  .waveLeft {
    padding-left: 8px;
    transform: translate(-12px, 0);
  }

  .waveRight {
    padding-right: 8px;
    transform: translate(12px, 0);
  }
}

@media (min-height: 576px) and (min-width: 576px) {
  .root {
    width: 40px;
    padding: 4px 0;
    margin-bottom: 6px;
  }

  .wave {
    fill: rgba($colorSmoothBlueHover, 0);
  }

  .hoveredDay .wave,
  .selectedDay .wave {
    fill: rgba($colorSmoothBlueHover, 1);
  }

  .waveRight {
    transform: translate(3px, 0);
  }

  .waveLeft {
    transform: translate(-3px, 0);
  }
}

/* Используем нативный hover только на десктопе, иначе залипает */
@media (hover: hover) {
  .root:hover,
  .hoveredDay:hover,
  .selectedDay:hover {
    background-color: rgb($colorBlue);
    color: rgb($colorWhite);
  }

  .availableDay:hover,
  .hoveredDay.availableDay:hover,
  .selectedDay.availableDay:hover,
  .selectedDay.animatedAvailableDay:hover {
    background-color: rgba($colorBlue, 1);
  }

  .hoveredDay:hover .wave,
  .selectedDay:hover .wave {
    fill: rgb($colorBlue);
  }

  .hoveredDay.availableDay:hover .wave,
  .selectedDay.availableDay:hover .wave {
    fill: rgba($colorBlue, 1);
  }

  .error .root:hover {
    color: rgb($colorWhite);
  }

  .error.selectedDay .waveRight,
  .error.selectedDay .waveLeft, {
    fill: rgb($colorSmoothRedHover);
    background-color: rgba($colorWhite, 0);
  }

  .error.selectedDay:active .waveRight,
  .error.selectedDay:active .waveLeft {
    fill: rgb($colorSmoothRedHover);
    background-color: rgba($colorWhite, 0);
  }
}
