@import "pages/variables.css";

.root {
  user-select: none;
  -webkit-tap-highlight-color: rgba($colorBlack, 0);
}

@media (max-height: 575px), (max-width: 575px) {
  .root {
    max-height: calc(100% - 192px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 16px;
  }
}

@media (min-height: 576px) and (min-width: 576px) {
  .root {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 320px;
    overflow-x: auto;
    padding-left: 32px;
  }

  .root::-webkit-scrollbar {
    height: 8px;
  }

  .root::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgb($colorSmoothBlue);
  }

  .root::-webkit-scrollbar-thumb {
    background: rgb($colorSmoothBlueHover);
    border-radius: 3px;

    &:hover {
      background: rgb($colorSmoothBlueActive);
    }
  }

  .root::-webkit-scrollbar-button {
    width: 16px;
  }

  .ruler {
    display: none;
  }
}
