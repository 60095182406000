@import 'pages/variables.css';

.root {
  min-width: 288px;
  padding: 16px;
}

.title {
  margin-bottom: 8px;
}

.selector {
  background-color: rgb($colorSmoothBlue);

  display: flex;
  align-items: center;

  border-radius: 4px;
  padding: 4px;
}

.item {
  flex: 1 0;
  width: 100%;
}

.selector .item input + label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 12px;
  line-height: 14px;

  width: 100%;
  padding: 10px 0;
}

.root .selector .item input + label {
  border-radius: 4px;
}

.root .selector .hasCheckOnLeft input + label {
  border-radius: 0 4px 4px 0;
}

.root .selector .hasCheckOnRight input + label {
  border-radius: 4px 0 0 4px;
}

.root .selector .hasCheckOnLeft.hasCheckOnRight input + label {
  border-radius: 0;
}

.root .selector .item:hover input:not(:checked) + label {
  border-radius: 4px;
}

/*
.selector .item:first-child input + label {
  border-radius: 4px 0 0 4px;
}

.selector .item:last-child input + label {
  border-radius: 0 4px 4px 0;
}
*/

@media (min-width: $breakpointSmall) {
  .root {
    padding: 0 32px;
    margin-bottom: 16px;
  }
}

@media (min-width: $breakpointMedium) {
  .root {
    padding: 0;
  }
}
