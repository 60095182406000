@import "pages/variables.css";

.root {
  margin: 0 0 12px;
}

.rulerDay {
  display: inline-block;
  width: calc(100% / 7);
  text-align: center;
  pointer-events: none;
}

/*
.weekend {
  color: rgb($colorError);
}
*/
