@import 'pages/variables.css';

header.root {
  background-color: rgb($colorWhite);
  border-bottom: 1px solid rgb($colorGray);
  padding: 8px 16px 12px;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: $zIndexMenu;
}

header.root.fixed,
header.root.hidden {
  position: fixed;
  box-shadow: $darkShadow6;
  border-bottom: none;
}

header.root.fixed {
  transition: transform $transition;
  transform: translateY(0);
}

header.root.hidden {
  transform: translateY(-120px);
}

header.root .filtersToggle {
  display: none;
}

header.isMapPage {
  position: static;
  left: unset;
  top: unset;
  box-shadow: $darkShadow6;
  height: 180px;
  transition: height $transition;
  border: none;
}

header.hideFilters {
  padding-bottom: 0;
  height: 104px;
}

header .searchBar,
header .filtersWrapper {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  height: auto;
  transition: transform $transition, opacity $transition;
}

header.hideFilters .searchBar,
header.hideFilters .filtersWrapper {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-24px);
  height: 0;
}

header.hideFilters .filtersWrapper {
  padding: 0;
}

header.hideFilters .filtersToggle {
  display: flex;
  width: calc(100% + 32px);
  transform: translateX(-16px);
}

.wrapper {
  display: grid;

  grid-column-gap: 16px;
  grid-template-columns: 134px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'l r'
    'c c';

  max-width: 1280px;
  margin: 0 auto;

  position: relative;
  z-index: 1;
}

.left {
  grid-area: l;
  position: relative;
  align-self: center;
}

.content {
  grid-area: c;
  padding-top: 16px;
}

.right {
  grid-area: r;
  align-self: center;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;
}

.filtersWrapper {
  padding-top: 16px;
  max-width: 1280px;
  margin: 0 auto;
}

header.root .mapButton {
  display: none;
}

@media all and (min-width: $breakpointTiny) {
  .wrapper {
    grid-column-gap: 8px;
  }
}

@media (min-width: $breakpointSmall) {
  header.root {
    padding: 16px 32px;
  }

  header.isMapPage,
  header.hideFilters {
    height: auto;
  }

  header.root.hidden {
    transform: translateY(-128px);
  }

  .wrapper {
    grid-column-gap: 32px;
    grid-template-columns: 240px 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    'l . r'
    'c c c';
  }
}

@media (min-width: $breakpointMedium) {
  header.root {
    border-bottom: none;
    padding: 0;
  }

  header.root.hidden {
    transform: translateY(-82px);
  }

  .wrapper {
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'l c r'
      '. c .';

    padding: 16px 32px;
  }

  .content {
    padding-top: 0;
  }

  .filtersWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
  }

  .filtersContainer {
    border-top: 1px solid rgb($colorGray);
  }

  header.root .mapButton {
    display: flex;
  }

  header.root .mobileMapButton {
    display: none;
  }
}
