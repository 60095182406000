@import 'pages/variables.css';

.root {
  border-top: 1px solid rgb($colorGray);
}

.container {
  display: grid;
  grid-row-gap: 8px;
  grid-template-areas:
    'copy'
    'links';

  padding: 24px 16px;

  max-width: 1270px;
  margin: 0 auto;
}

.copy {
  grid-area: copy;
}

.links {
  grid-area: links;
  display: flex;
  flex-wrap: wrap;
}

.linkItem {
  padding-bottom: 4px;
}

.linkItem:not(:last-child) {
  position: relative;
  padding-right: 22px;
}

.linkItem:not(:last-child)::after {
  content: "·";
  position: absolute;
  right: 8px;
}

@media (min-width: $breakpointSmall) {
  .container {
    padding: 24px 32px 32px;
  }
}

@media (min-width: $breakpointMedium) {
  .container {
    padding: 24px 32px 40px;
    grid-column-gap: 40px;
    grid-row-gap: 0;
    grid-template-columns: auto 1fr;
    grid-template-areas:
    'copy links';
  }

  .links {
    justify-self: right;
  }
}
