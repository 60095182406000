@import 'pages/variables.css';

.root {
  position: absolute;
  bottom: 0;
  left: 0;

  max-height: calc(100% - 16px);
  width: 100%;

  display: grid;

  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'h'
    'c'
    'f';

  background-color: rgb($colorWhite);
  opacity: 0;
  border-radius: 8px 8px 0 0;

  transform: translateY(100%);
  transition: transform $transition, opacity $transition;
}

.root.withoutFooter {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'h'
    'c';
}

.root.rendered {
  opacity: 1;
  transform: translateY(0);
}

.overlay {
  display: none;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: $zIndexModal;

  background-color: rgba($colorBlack, 0.25);
}

.overlay.opened {
  display: block;
}

.header {
  grid-area: h;

  border-bottom: 1px solid rgb($colorGray);
}

.headerWrapepr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  align-items: center;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin-left: 20px;
  align-self: flex-start;

  &:hover {
    & svg rect {
      fill: rgba($colorGray60);
    }
  }
}

.content {
  grid-area: c;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  grid-area: f;

  border-top: 1px solid rgb($colorGray);

  display: flex;
  flex-direction: column;

  padding: 8px 16px 12px;
}

.footerChildren {
  margin: 0;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  padding-top: 0;
}

.buttons {
  display: flex;
  gap: 12px;
}

.submit, .cancelSubmit {
  width: 100%;
}

.footerWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.errorText {
  margin-bottom: 12px;
}

@media (min-width: $breakpointSmall) and (min-height: 500px) {
  .overlay.opened {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .root {
    bottom: unset;
    left: unset;

    width: unset;
    height: auto;
    min-width: 384px;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);

    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'h'
      'c'
      'f';

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    transform: translateY(-24px);
  }

  .root.withoutFooter {
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'h'
      'c';
  }

  .header {
    border-bottom: 0;
  }

  .headerWrapepr {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .footer {
    border-top: 0;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    padding: 18px 32px 24px;
  }

  .footerChildren {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .close {
    align-self: flex-start;
  }

  .submit, .cancelSubmit {
    width: auto;
  }

  .footerWrapper {
    width: auto;
  }

  .errorText {
    margin-bottom: 8px;
  }

  .title {
    padding-top: 8px;
  }
}
