@import 'pages/variables.css';

.wrapper {
  position: relative;
}

.wrapper:not(:last-child) {
  margin-right: 8px;
}

.wrapper.notInFilters:not(:last-child) {
  margin-right: 0;
}

.wrapper .dropdown {
  max-height: unset;
  right: 0;
}

.wrapper .modalFooter {
  border-top: 1px solid rgb($colorGray);
}

.calendarFooterChildren {
  margin: 0 0 8px 0;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.btn {
  position: relative;
  white-space: nowrap;
}

.btn span {
  font-size: 14px;
}

.btn.hasChanges::after {
  content: "";
  position: absolute;
  right: -4px;
  top: -4px;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb($colorBlue);
}

.btn.tinyCalendarBtnText span {
  font-size: 12px;
}

@media (min-width: $breakpointTiny) {
  .btn span {
    font-size: 16px;
  }

  .btn.tinyCalendarBtnText span {
    font-size: 16px;
  }
}


@media (min-width: $breakpointSmall) {
  .mobileOnly {
    display: none;
  }

  .calendarFooterChildren {
    margin: 0 12px 0 0;
  }
}

@media (min-width: $breakpointMedium) {
  .wrapper .dropdownGuests {
    right: unset;
    left: 0;
  }
}
