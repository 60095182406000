@import 'pages/variables.css';

.dropdownButton {
  background-color: rgb($colorSmoothBlue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
  transition: background-color $transition;

  &:hover {
    background-color: rgb($colorSmoothBlueHover);
  }

  &:active {
    background-color: rgb($colorSmoothBlueHover);
  }
}

.dropdownButton.hasError {
  background-color: rgb($colorSmoothRed);
}

.dropdownButton.hasError span {
  color: rgb($colorRed);
}

.dropdownButton.hasError svg path {
  fill: rgb($colorRed);
}

.dropdownButton.lg {
  padding: 12px 16px;
  height: 48px;
}

.dropdownButton.md {
  padding: 8px 16px;
  height: 40px;
}

.dropdownButton.block {
  width: 100%;
}

.compactText {
  margin-right: 8px;
}

.text {
  margin-right: 4px;
}

.dropdownButton.compact .text {
  display: none;
}

.dropdownButton.isActive {
  background-color: rgb($colorSmoothBlueHover);
}

.dropdownArrow {
  transition: transform $transition;
}

.dropdownIcon,
.dropdownArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noBackground.dropdownButton.lg, .noBackground.dropdownButton.lg:hover {
  background: transparent;
  padding: 0;
}

.noBackground:hover span {
  color: rgb($colorHover)
}

.noBackground:hover div svg path {
  stroke: rgb($colorHover)
}

@media (min-width: $breakpointMedium) {
  .compactText {
    margin-right: 16px;
  }

  .text {
    margin-right: 8px;
  }
}
