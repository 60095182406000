@import "pages/variables.css";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  user-select: none;
  margin: 0 0 16px;
}

.slider {
  width: 100%;
  display: flex;
  align-items: center;
}

.track {
  height: 1px;
  background-color: rgb($colorGray);

  &:nth-child(2) {
    background-color: rgb($colorBlue);
  }
}

.thumb {
  width: 13px;
  height: 13px;
  background-color: rgb($colorBlue);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color $transition, transform $transition;

  &::before {
    content: "";
    position: absolute;
    left: -6px;
    top: -5px;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  &:hover {
    background-color: rgb($colorHover);
    transform: scale(1.25);
  }

  &:active {
    background-color: rgb($colorHover);
    transform: scale(1.25);
  }
}


