@import 'pages/variables.css';

/* Телефоны */
.root {
  width: 100%;
  height: 100%;
  min-width: 304px; /* 320px - 16px (margin) */

  display: grid;

  grid-column-gap: 0;
  grid-row-gap: $layoutRowGapMobile;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'h'
    'm'
    'f';
}

.tallHeader {
  grid-template-rows: auto 1fr auto;
}

.app {
  grid-template-areas:
    'm'
    'f';
  grid-template-rows: 1fr auto;
}

.header {
  grid-area: h;
  position: relative;
  z-index: $zIndexMenu;
}

.content {
  grid-area: m;
  max-width: 100%;
  padding: 0 16px;
}

.content.withFilters {
  padding-top: 194px;
}

.footer {
  grid-area: f;
  position: relative;
  margin-top: 40px;
}

/* Central layout */
.central {
  padding: 0;
  grid-row-gap: 0;
  grid-template-areas:
      'h h h h h h h h h h h h h h'
      'm m m m m m m m m m m m m m'
      'f f f f f f f f f f f f f f';
}

/* Search bar */
.searchbarWrapper {
  max-width: 100%;
  margin: 0 auto 16px auto;
}

.searchbarWrapper button {
  display: block;
}

.noTopLine {
  height: 6px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: -18px;
  background: rgb($colorWhite);
  position: relative;
  z-index: 11;
}

.disable_mobile_scroll {
  overflow-x: hidden;
}

@media (min-width: $breakpointSmall) {
  /* Планшеты */
  .root {
    grid-column-gap: 24px;
    grid-row-gap: $layoutRowGapDesktop;

    grid-template-rows: auto 1fr auto;
    grid-template-columns: 6px repeat(8, 1fr) 6px;

    grid-template-areas:
      'h h h h h h h h h h'
      '. m m m m m m m m .'
      'f f f f f f f f f f';
  }

  .root_1_column {
    grid-template-columns: 1fr;
  }

  .tallHeader {
    grid-template-rows: auto 1fr auto;
  }

  .content {
    padding: 0;
  }

  /* Central layout */
  .central {
    grid-row-gap: 0;
    grid-template-areas:
      'h h h h h h h h h h h h h h'
      'm m m m m m m m m m m m m m'
      'f f f f f f f f f f f f f f';
  }

  .footer {
    margin-top: 48px;
  }

  .central .footer {
    margin-top: 0;
  }

  /* Search bar */
  .searchbarWrapper {
    margin: 0 auto 16px auto;
  }

  .noTopLine {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-top: -34px;
  }

  .searchbarWrapper button {
    display: none;
  }
}

@media (min-width: $breakpointMedium) {
  /* Десктопы */
  .root {
    grid-column-gap: 24px;
    grid-row-gap: $layoutRowGapDesktop;

    grid-template-rows: auto 1fr auto;
    grid-template-columns: 6px repeat(12, 1fr) 6px;

    grid-template-areas:
      'h h h h h h h h h h h h h h'
      '. m m m m m m m m m m m m .'
      'f f f f f f f f f f f f f f';
  }

  .tallHeader {
    grid-template-rows: auto 1fr auto;
  }

  .content.withFilters {
    padding-top: 130px;
  }

  /* Central layout */
  .central {
    grid-row-gap: 0;
    grid-template-areas:
      'h h h h h h h h h h h h h h'
      'm m m m m m m m m m m m m m'
      'f f f f f f f f f f f f f f';
  }
}

@media (min-width: $breakpointLarge) {
  /* Широкие десктопы */
  .root {
    grid-column-gap: 23px;
    grid-row-gap: $layoutRowGapDesktop;

    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr repeat(12, 80px) 1fr;

    grid-template-areas:
      'h h h h h h h h h h h h h h'
      '. m m m m m m m m m m m m .'
      'f f f f f f f f f f f f f f';
  }

  .content.withFilters {
  }

  /* Central layout */
  .central {
    grid-row-gap: 0;
    grid-template-areas:
      'h h h h h h h h h h h h h h'
      'm m m m m m m m m m m m m m'
      'f f f f f f f f f f f f f f';
  }
}

@media (max-width: $breakpointSmall) {
  .footer_only_desktop.footer_only_desktop {
    display: none;
  }
}

@media (max-width: 360px) {
  .root {
    height: auto;
  }
}
