@import 'pages/variables.css';

.root {
  position: absolute;
  left: 0;
  background-color: rgb($colorWhite);
  z-index: $zIndexSuggests;
}

.mobile {
  top: 50px;
  width: 100vw;
  transform: translateX(-16px);
  overflow-y: auto;
}

.desktop {
  display: none;
}

.central {
  top: 58px;
  padding-left: 16px;
  width: calc(100vw + 16px);
}

@media all and (min-width: $breakpointSmall) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
    top: 52px;
    width: 100%;

    box-shadow: 0 0 8px rgba(8, 35, 48, 0.3);
    border-radius: 4px;
  }
}
