@import 'pages/variables.css';

.root {
  min-width: 288px;
  padding: 16px;
  margin-bottom: 16px;
}

.title {
  margin-bottom: 8px;
}

.variants {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
  background-color: rgb($colorSmoothBlue);
}

.variant {
  flex: 1 0;
  width: 100%;
}

.variants .variant input + label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  padding: 4px 0;
}

.root .variants .variant input + label {
  border-radius: 4px;
}

.root .variants .hasCheckOnLeft input + label {
  border-radius: 0 4px 4px 0;
}

.root .variants .hasCheckOnRight input + label {
  border-radius: 4px 0 0 4px;
}

.root .variants .hasCheckOnLeft.hasCheckOnRight input + label {
  border-radius: 0;
}

.root .variants .variant:hover input:not(:checked) + label {
  border-radius: 4px;
}

@media (min-width: $breakpointSmall) {
  .root {
    padding: 0 32px;
  }
}

@media (min-width: $breakpointMedium) {
  .root {
    padding: 0;
  }
}
