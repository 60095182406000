@import 'pages/variables.css';

.root {
  text-align: end;
  width: max-content;
}

div .compactSize {
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin: 0;
}

@media all and (min-width: $breakpointSmall) {
  .root {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: start;
  }

  div .compactSize {
    font-size: 14px;
    line-height: 20px;
  }
}
