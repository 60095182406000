@import "pages/variables.css";

.search {
  width: 100%;

  display: block;

  border: none;
  outline: none;

  padding: 8px 8px 8px 0;

  text-overflow: ellipsis;
}

.search::placeholder {
  color: rgb($colorBlack);
}

.searchActive::placeholder {
  color: rgb($colorGray60);
}

.search.central::placeholder {
  color: rgb($colorGray60);
}
