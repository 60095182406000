@import 'pages/variables.css';

.root {
  border-top: 1px solid rgb($colorGray);
}

.root .container {
  max-width: 1270px;
}

.container {
  display: flex;
  flex-direction: column;

  padding: 24px 16px 32px;
  margin: 0 auto;
}

.sections {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-bottom: 24px;
}

.section:first-child {
  margin-left: 0;
}

.section.apps {
  order: -1;
}

@media (min-width: $breakpointSmall) {
  .root.central {
    border-top: none;
  }

  .container {
    padding: 24px 32px 32px;
  }

  .sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }

  .section.apps {
    order: unset;
  }
}

@media (min-width: $breakpointMedium) {
  .container {
    padding: 24px 32px 40px;
  }

  .sections {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 24px;
    margin-bottom: 40px;
  }
}
