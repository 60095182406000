@import 'pages/variables.css';

.root {
  min-width: 288px;
  padding: 0 16px;
  margin-bottom: 16px;
}

.variants {
  display: flex;
  flex-direction: column;
}

.variant {
  border-bottom: 1px solid rgb($colorGray);
}

@media (min-width: $breakpointSmall) {
  .root {
    padding: 0 32px;
  }

  .variants {
    align-items: normal;
    background-color: rgb($colorWhite);
  }
}

@media (min-width: $breakpointMedium) {
  .root {
    padding: 0;
  }
}
