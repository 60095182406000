@import 'pages/variables.css';

.root {
  display: flex;
  user-select: none;
}

.root label {
  position: relative;
  padding: 16px 0;
  cursor: pointer;
}

.root input {
  display: none;
}

/* Toggle (default) */
.toggle label {
  display: flex;
  align-items: flex-start;
  padding-left: 36px;
  color: rgb($colorBlue);
}

.toggle input + label::before {
  display: block;
  content: '';

  width: 24px;
  height: 24px;

  background-color: rgb($colorSmoothBlue);
  border-radius: 4px;

  position: absolute;

  left: 0;
}

.toggle input.checked + label::before {
  @svg-load check url('components/common/icons/checkbox-check.svg') {
    path {
      fill: rgb($colorBlue);
    }
  }

  background: rgb($colorSmoothBlue) svg-inline(check) no-repeat center;
}

.root.toggle.disabled label {
  color: rgb($colorGray60);
  cursor: no-drop;
}

.root.toggle.disabled input + label::before {
  background-color: rgb($colorGray);
}

/* Switch */
.root.switch {
  justify-content: space-between;
  align-items: center;
}

.root.switch label {
  width: 100%;
}

.root.switch input + label::before {
  display: block;
  content: '';

  width: 36px;
  height: 20px;

  background-color: rgb($colorSmoothBlue);
  border-radius: 10px;

  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 0;
}

.root.switch input + label::after {
  display: block;
  content: '';

  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 16px;

  width: 20px;
  height: 20px;

  background-color: rgb($colorSmoothBlueHover);
  border-radius: 10px;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.14),
    0 1px 3px rgba(0, 0, 0, 0.2);

  transition:
    right 0.15s ease-in,
    background-color 0.15s ease-out;
}

.root.switch input:checked + label::before {
  background-color: rgb($colorSmoothBlueHover);
}

.root.switch input:checked + label::after {
  background-color: rgb($colorBlue);
  right: 0;
}

/* Tab */
.root.tabs input + label {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb($colorSmoothBlue);
  color: rgb($colorBlue);
  font-size: 16px;
  line-height: 20px;

  padding: 10px 0;
}

.root.tabs:first-child input + label {
  border-radius: 4px 0 0 4px;
}

.root.tabs:last-child input + label {
  border-radius: 0 4px 4px 0;
}

.root.tabs input:checked + label {
  background-color: rgb($colorSmoothBlueHover);
  color: rgb($colorBlack);
}

/* Button */
.root.button input + label {
  padding: 4px 12px 4px 8px;

  background-color: rgb($colorSmoothBlue);
  border-radius: 4px;
  color: rgb($colorBlue);

  transition:
    background-color 0.25s ease,
    color 0.25s ease;
}

.root.button input:checked + label {
  background-color: rgb($colorSmoothBlueHover);
  color: rgb($colorBlack);
}

.root.button input + label::before,
.root.tabs input + label::before {
  display: none;
}

.root.button input + label::after,
.root.tabs input + label::after {
  display: none;
}

/* Large button */
.root.button input + label.large {
  padding: 12px;
  width: 100%;
  text-align: center;
}

/* desktop */
@media all and (min-width: $breakpointSmall) {
  .root.button input + label:hover {
    background-color: rgb($colorSmoothBlueHover);
  }
}
