@import 'pages/variables.css';

.root {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-24px);
  height: 0;
  width: 0;
  max-height: 280px;
  overflow-y: auto;
  background-color: rgb($colorWhite);
  box-shadow: 0 0 8px rgba(8, 35, 48, 0.3);
  border-radius: 4px;
  z-index: -1;
  transition: transform $transition, opacity $transition;
}

.opened {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  height: auto;
  width: auto;

  min-width: 240px;

  position: absolute;
  top: 52px;
  z-index: $zIndexDropdown;
}

.opened.block {
  width: 100%;
}

.opened.top {
  top: unset;
  bottom: 52px;
}

.opened.left {
  right: 0;
}

.footer {
  padding: 8px 16px 16px;
}
