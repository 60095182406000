@import 'pages/variables.css';

.root {
  margin-top: 24px;
  padding: 0 16px;
}

.section {
  padding-bottom: 24px;
}

.addon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid rgb($colorGray);
}

.icon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
}

.checkbox {
  width: 100%;
}

.label {
  display: flex;
  align-items: center;
}

.root.buttons .section {
  margin-bottom: 8px;
}

.root.buttons .addon {
  margin-right: 8px;
  margin-bottom: 8px;
}

.root.buttons .addon:last-child {
  margin-right: 0;
}

@media (min-width: $breakpointSmall) {
  .root {
    padding: 16px 32px;
    margin-bottom: 16px;
  }
}

@media (min-width: $breakpointMedium) {
  .section {
    display: block;
    padding-bottom: 0;
  }

  .addon {
    display: inline-block;
    border-bottom: none;
  }

  .checkbox {
    width: auto;
  }
}
