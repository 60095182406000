@import 'pages/variables.css';

.root {
  min-width: 288px;
  padding: 16px;
  margin-bottom: 16px;
}

.variant {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 0;

  border-bottom: 1px solid rgb($colorGray);
}

@media (min-width: $breakpointSmall) {
  .root {
    padding: 0 32px;
  }
}

@media (min-width: $breakpointMedium) {
  .root {
    padding: 0;
  }
}
