@import 'pages/variables.css';

.root {
}

.title {
  margin-bottom: 16px;
}

.apps .title {
  margin-bottom: 8px;
}

.description {
  margin-bottom: 16px;
}

.links {
  display: flex;
  flex-direction: column;
}

.apps .links,
.social .links {
  flex-direction: row;
}

.link {
  width: fit-content;
  position: relative;
}

.link:not(:last-child) {
  margin-bottom: 16px;
}

.apps .link {
  margin-bottom: 0;
}

.apps .link:first-child {
  margin-right: 8px;
}

.onlineChat,
.vkLinkGuest,
.vkLinkOwner,
.tgLinkGuest,
.tgLinkOwner,
.instagram,
.dzen,
.okGuest,
.okOwner {
  padding-left: 24px;
  background-repeat: no-repeat;
}

.onlineChat {
  background-position: left center;
}

.link.vkLinkGuest,
.link.vkLinkOwner,
.link.tgLinkGuest,
.link.tgLinkOwner,
.link.instagram,
.link.dzen,
.link.okGuest,
.link.okOwner {
  font-size: 0;
  line-height: 0;
  background-size: contain;
  background-position: center;
  margin-bottom: 0;
  margin-right: 24px;
}

.onlineChat {
  margin-bottom: 16px;
  color: rgb($colorBlue);
  cursor: pointer;
  @svg-load chat url('components/layout/footer/icons/chat.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(chat);
}

.onlineChat:hover {
  color: rgb($colorHover);
  @svg-load chat url('components/layout/footer/icons/chat.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(chat);
}

.vkLinkGuest,
.vkLinkOwner {
  @svg-load vk url('components/layout/footer/icons/vk.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(vk);
}

.vkLinkGuest:hover,
.vkLinkOwner:hover {
  @svg-load vk url('components/layout/footer/icons/vk.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(vk);
}

.tgLinkGuest,
.tgLinkOwner {
  @svg-load telegram url('components/layout/footer/icons/telegram.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(telegram);
}

.tgLinkGuest:hover,
.tgLinkOwner:hover {
  @svg-load telegram url('components/layout/footer/icons/telegram.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(telegram);
}

.instagram {
  @svg-load instagram url('components/layout/footer/icons/instagram.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(instagram);
}

.instagram:hover {
  @svg-load instagram url('components/layout/footer/icons/instagram.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(instagram);
}

.dzen {
  @svg-load dzen url('components/layout/footer/icons/dzen.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(dzen);
}

.dzen:hover {
  @svg-load dzen url('components/layout/footer/icons/dzen.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(dzen);
}

.okGuest,
.okOwner {
  @svg-load ok url('components/layout/footer/icons/ok.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(ok);
}

.okGuest:hover,
.okOwner:hover {
  @svg-load vk url('components/layout/footer/icons/ok.svg') {
    path {
      fill: rgb($colorWhite);
    }

    #color {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(vk);
}

.footerLinksIos,
.footerLinkAndroid {
  font-size: 0;
  line-height: 0;
  height: 40px;
  width: 134px;
  border: 1px solid rgba($colorBlue, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}

.footerLinksIos:hover,
.footerLinkAndroid:hover,
.footerLinksIos:visited:hover,
.footerLinkAndroid:visited:hover {
  border: 1px solid rgb($colorHover);
}

.footerLinksIos {
  @svg-load ios url('components/layout/footer/icons/ios.svg') {
    path {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(ios);
  background-size: 104px 28px;
}

.footerLinksIos:hover {
  @svg-load ios url('components/layout/footer/icons/ios.svg') {
    path {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(ios);
}

.footerLinkAndroid {
  @svg-load android url('components/layout/footer/icons/android.svg') {
    path {
      fill: rgb($colorBlue);
    }
  }
  background-image: svg-inline(android);
  background-size: 114px 27px;
}

.footerLinkAndroid:hover {
  @svg-load android url('components/layout/footer/icons/android.svg') {
    path {
      fill: rgb($colorHover);
    }
  }
  background-image: svg-inline(android);
}

@media (min-width: $breakpointSmall) {
  .apps .title {
    margin-bottom: 16px;
  }

  .apps .links,
  .social .links {
    flex-direction: column;
  }

  .apps .link:not(:last-child) {
    margin-bottom: 16px;
  }

  .apps .link:first-child {
    margin-right: 0;
  }

  .link.vkLinkGuest,
  .link.vkLinkOwner,
  .link.tgLinkGuest,
  .link.tgLinkOwner,
  .link.instagram,
  .link.dzen,
  .link.okGuest,
  .link.okOwner {
    font-size: unset;
    line-height: unset;
    background-size: 16px 24px;
    background-position: left center;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
