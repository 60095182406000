@import 'pages/variables.css';

div .ruler {
  margin: 0 0 5px;
  padding: 0 8px;
}

div .calendarModal {
  width: 100%;
  max-width: 1096px;
}

div .footerWithSkipBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}

div .footerWithSkipBtn button {
  width: fit-content;
}

@media (min-width: $breakpointSmall) {
  .ruler {
    display: none;
  }

  div .calendarModal {
    width: 80%;
    min-width: 570px;
  }
}
