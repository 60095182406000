@import 'pages/variables.css';

.root {
  display: grid;
  grid-row-gap: 24px;
  grid-template-rows: 1fr;
  grid-template-areas:
    'payments'
    'info';
}

.info {
  grid-area: info;
}

.copy {
  margin-bottom: 8px;
}

.payments {
  grid-area: payments;
}

.paymentsList {
  display: flex;
}

.paymentsItem:not(:last-child) {
  margin-right: 8px;
}

@media (min-width: $breakpointSmall) {
  .root {
    grid-row-gap: 0;
    grid-column-gap: 24px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
    'info payments';
  }

  .payments {
    justify-self: right;
  }
}
