@import 'pages/variables.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 32px 0;
}

.slider {
  margin: 0 0 30px;
  width: 100%;
}

@media (min-width: $breakpointSmall) {
  .slider {
    min-width: 300px;
    max-width: 500px;
  }
}

@media (min-width: $breakpointMedium) {
  .root {
    padding: 0;
  }
}
