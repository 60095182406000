@import 'pages/variables.css';

.root {
  z-index: 2;
  margin-left: 8px;
}

.root .cancelButton {
  margin-left: 8px;
}

@media all and (min-width: $breakpointMedium) {
  .root .cancelButton {
    display: none;
  }
}
