@import 'pages/variables.css';

.value {
  margin-right: 0.2em;
}

.priceModern {
  text-overflow: ellipsis;
  /*  8 * 2 (отступ) + 1 (между ячейками) + 2 (компенсация округления) = 19 */
  width: calc(100vw / 7 - 19px);
  margin-right: 0;
  overflow: hidden;
  display: block;
}

/* .discount {
  text-decoration: line-through;
  color: $colorGray;
} */

.discount {
	position: relative;
	color: #000;
	text-decoration: none;
}
.discount:before {
	content: "";
	border-bottom: 1.5px solid;
  border-color: #000;
	position: absolute;
	top: 2px;
	left: 0;    
	width: 100%;
	height: 50%;
}

@media (min-width: $breakpointMedium) {
  .priceModern {
    width: 100%;
  }   
}

@media (max-width: 376px) {
  .priceModern {
    /*  4 * 2 + 1 + 2 = 11 */
    width: calc(100vw / 7 - 11px);
  }
}

@media (max-width: $breakpointTiny) {
  .priceModern {
    width: calc(100vw / 7 - 1px);
  }
}