@import 'pages/variables.css';

.root {
  border-bottom: 1px solid rgb($colorGray);
  background: rgb($colorWhite);
}

.root.mobileSearch {
  border-bottom: 0;
}

.wrapper {
  display: grid;

  grid-column-gap: 16px;
  grid-template-columns: 134px 1fr;
  grid-template-areas:
      'l ct r'
      'c c c';

  height: 100%;
  max-width: 1280px;

  margin: 0 auto;
  padding: 8px 16px;
}

.centerTop {
  grid-area: ct;
}

.left {
  grid-area: l;
  position: relative;
  align-self: center;
}

.content {
  display: none;
  grid-area: c;

  position: relative;
}

.content.mobileSearch {
  display: flex;
  margin-top: 16px;
}

.right {
  grid-area: r;
  align-self: center;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;
}

/* central header */
.central {
  border-bottom-color: transparent;
  background: transparent;
}

@media all and (min-width: $breakpointTiny) {
  .wrapper {
    grid-column-gap: 8px;
    grid-template-columns: 120px 1fr;
  }
}

@media all and (min-width: 375px) {
  .wrapper {
    grid-template-columns: 134px 1fr;
  }
}

@media (min-width: $breakpointSmall) {
  .wrapper {
    grid-column-gap: 32px;
    grid-template-columns: 240px 1fr auto;
    /*grid-template-areas: 'l c r';*/

    padding: 16px 32px;
  }
}

@media (min-width: $breakpointMedium) {
  .wrapper {
    grid-template-areas: 'l c r';
  }

  .content {
    display: flex;
    align-items: center;
  }

  .content.mobileSearch {
    display: flex;
    align-items: center;
    grid-area: c;
    margin-top: 0;
  }

  .root.mobileSearch {
    border-bottom: 1px solid rgb($colorGray);
  }
}

@media (max-width: 320px) {
  .wrapper {
    grid-column-gap: 0
  }
}