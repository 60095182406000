@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.desktopButtons {
  display: none;
}

.mobileButtons {
  display: flex;
}

@media (min-width: $breakpointMedium) {
  .root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .desktopButtons {
    display: flex;
  }

  .mobileButtons {
    display: none;
  }
}
